import { Controller } from '@hotwired/stimulus'
import { useTransition } from 'stimulus-use'
export default class TransitionController extends Controller {
  static targets = ['element']
  connect() {
    requestAnimationFrame(() => {
      useTransition(this, { element: this.selfOrTarget })
    })
  }

  enterTheBar() {
    setTimeout(() => {
      this.selfOrTarget.classList.remove('hidden')
      this.enter()
    }, 100)
  }

  get selfOrTarget() {
    this.hasElementTarget ? this.elementTarget : this.element
  }
}
