import { Application } from '@hotwired/stimulus'

let application

if (window.Stimulus === undefined) {
  application = Application.start()
  // Configure Stimulus development experience
  application.debug = false
  window.Stimulus = application
  document.documentElement.classList.add('js')
} else {
  application = window.Stimulus
}

export { application }
