import ApplicationController from '../../../javascript/controllers/application-controller.js'

export default class extends ApplicationController {
  static targets = ['submitButton']

  connect() {}

  async submit(event) {
    if (this.submitRequested) return
    event.preventDefault()
    event.stopImmediatePropagation()
    // this.#lockButtons()
    this.dispatch('submit-start')
    this.errorDismissed = false
    await this.#processSubmit({ event })
    // setTimeout(() => this.#unlockButtons(), 5000) //failsafe
  }

  async handleInvalid(event) {
    this.errorShown || this.dispatch('submit-finish')
    if (!this.errorDismissed) {
      event.preventDefault()
      this.errorShown || this.dispatch('submit-error')
      this.errorShown = true
    }
  }

  async dismissError() {
    this.errorShown = false
    this.errorDismissed = true
    this.form.reportValidity()
  }

  async dismissSuccess() {
    this.form.reset()
  }

  async #processSubmit(args) {
    if (this.#validate()) {
      this.#submitSomhow(args)
    } else {
      this.dispatch('submit-error')
    }
    this.dispatch('submit-finish')
  }

  #validate() {
    return this.form.checkValidity()
  }

  #submitSomhow({ event: { target } }) {
    this.submitRequested = true
    const overrideValue = target.getAttribute('value')
    if (overrideValue) {
      const toUpdate = this.element.querySelector(`input[type='hidden'][name='${target.getAttribute('name')}']`)
      if (toUpdate) {
        toUpdate.value = overrideValue
      }
    }
    this.element.requestSubmit()
  }

  #lockButtons() {
    this.submitButtonTargets.forEach(b => b.setAttribute('disabled', 'disabled'))
    setTimeout(() => {
      this.#unlockButtons()
    }, 5000)
  }

  async #unlockButtons() {
    this.submitButtonTargets.forEach(b => b.removeAttribute('disabled'))
  }

  get form() {
    return this.element
  }
}
