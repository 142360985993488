import { Controller } from '@hotwired/stimulus'
import { useMemo } from 'stimulus-use'

export default class ScrollTogglerController extends Controller {
  static values = {
    scrollY: Number,
    debounceOffset: Number,
    measureItemDimensionValue: String
  }

  static memos = ['targetY']
  static classes = ['triggered']
  static targets = ['item', 'measureItem']

  connect () {
    useMemo(this)
    this.triggered = false
    this.handleScroll()
  }

  handleScroll () {
    const { scrollY } = window

    if ((scrollY >= this.targetY && this.triggered) || (scrollY < this.targetY && !this.triggered)) return

    this.items.forEach(target => target.classList[this.triggered ? 'remove' : 'add'](this.triggeredClass))
    this.triggered = !this.triggered
  }

  debounceGuard (scrollY) {
    if (this.memoizedTargetY && scrollY >= this.memoizedTargetY && this.triggered) return false

    const targetY = this.targetY
    if (scrollY >= targetY && this.triggered) {
      this.memoizedTargetY = targetY
      return true
    }

    this.memoizedTargetY = 0
    return false
  }

  get targetY () {
    return this.scrollYValue || this.measureItem.getBoundingClientRect()[this.measureItemDimension] || 0
  }

  get measureItem () {
    return this.hasMeasureItemTarget ? this.measureItemTarget : this.element
  }

  get measureItemDimension () {
    return this.measureItemDimensionValue || 'height'
  }

  get items () {
    return this.hasItemTargets ? this.itemTargets : [this.element]
  }
}
