import { Controller } from '@hotwired/stimulus'

export default class NewsletterSubscriptionController extends Controller {
  static targets = ['email', 'subscribeButton', 'message']
  static values = { url: String }

  connect() {
    this.subscribeButtonTarget.disabled = false
    this.emailTarget.focus()
  }

  async subscribe(clickEvent) {
    if (this.subscribeButtonTarget.disabled) return
    clickEvent.preventDefault()
    this.subscribeButtonTarget.disabled = true
    this.buttonText = this.subscribeButtonTarget.textContent
    this.subscribeButtonTarget.textContent = `${this.buttonText}...`
    try {
      await this.#submitForm()
    } finally {
      this.subscribeButtonTarget.disabled = false
      this.subscribeButtonTarget.textContent = this.buttonText
    }
  }

  async #submitForm() {
    const response = await fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
      },
      body: JSON.stringify({ email: this.emailTarget.value })
    })

    if (response.ok) {
      this.messageTarget.textContent = 'Subscribed!'
    } else {
      this.#processErrorResponse(response)
    }
  }

  async #processErrorResponse(response) {
    const body = await response.json()
    const errors = body.errors
    if (errors?.email) {
      this.messageTarget.textContent = errors.email.join(', ')
    } else {
      this.messageTarget.textContent = 'Something went wrong. Please try again.'
    }
  }
}
