import { ApplicationController } from 'stimulus-use'

export default class MapController extends ApplicationController {
  static targets = ['map', 'marker']
  static values = {
    mapId: String
  }

  async connect() {
    await this.awaitMapsApi()
    const { Map } = await globalThis.google.maps.importLibrary('maps')

    this.map = new Map(this.mapElement, {
      // zoom: 8,
      mapId: this.mapIdValue
    })

    const { LatLngBounds } = await globalThis.google.maps.importLibrary('core')
    this.bounds = new LatLngBounds()

    this.buildMarkers()
  }

  async awaitMapsApi() {
    if (globalThis.google && globalThis.google.maps) return

    setTimeout(() => this.awaitMapsApi(), 100)
  }

  async buildMarkers() {
    const { AdvancedMarkerView } = await globalThis.google.maps.importLibrary('marker')
    this.markerTargets.forEach(markerTarget => {
      const marker = new AdvancedMarkerView({
        position: {
          lat: Number.parseFloat(markerTarget.dataset.lat),
          lng: Number.parseFloat(markerTarget.dataset.lng)
        },
        map: this.map,
        title: 'Pawn Shop'
        // content: markerTarget
      })

      this.bounds.extend(marker.position)
    })

    this.map.fitBounds(this.bounds)
    this.map.panToBounds(this.bounds)
  }

  get mapElement() {
    return this.hasMapTarget ? this.mapTarget : this.element
  }
}
